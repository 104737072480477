export default <Record<string, ManageRoute>>{
  '/lists/:companyuser/:moduleId/:jobid/:subtemplate/:callfilter': {
    as: 'lists',
    hasRocketchat: true,
    module: () => import('.'),
  },
  '/lists/:companyuser/:moduleId/:jobid/:subtemplate': {
    as: 'lists',
    hasRocketchat: true,
    module: () => import('.'),
  },
  '/lists/:companyuser/:moduleId/:jobid': {
    as: 'lists',
    hasRocketchat: true,
    module: () => import('.'),
  },
  '/lists/:companyuser/:moduleId': {
    as: 'lists',
    hasRocketchat: true,
    module: () => import('.'),
  },
};
