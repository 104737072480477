import '@shoelace-style/shoelace/dist/components/alert/alert.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@yoummday/yd-components';
import '@yoummday/ymmd-platform-core/comp/app-link';
import '@yoummday/ymmd-platform-core/comp/user-profile';
import '@yoummday/ymmd-platform-core/comp/user-thumb';

import { render } from 'lit';
import { User } from './modules/User';
import { createLogoutEvent } from '@yoummday/ymmd-platform-core/comp/app-shell';
import {
  addAuthenticationErrorHandling,
  addAuthorizationErrorHandling,
  addApiErrorMessages,
} from '@yoummday/ymmd-api-client';
import { Log } from '@yoummday/ymmd-logger';
import { API, legacyAPI, UploadAPI } from './modules/api';

import moment from 'moment-timezone';
import 'moment/dist/locale/de.js';
import { matomoTracker } from './modules/matomoTracker';

import './utils/formSerializeObject.js';
import { i18n } from './i18n';
import './components/app-shell';

const app = document.createElement('app-manage');

export const authorizationMiddleWare = addAuthorizationErrorHandling(() => {
  app.view?.remove();
  app.onShowMessage({
    detail: {
      variant: 'danger',
      str: i18n.rawText?.error.notallowed || '',
    },
  });
});

export const authenticationMiddleWare = addAuthenticationErrorHandling(() => {
  app.dispatchEvent(createLogoutEvent(false));
});

export const errorMessageMiddleWare = addApiErrorMessages(
  () => i18n.rawText!.error,
  (errorMsg, errorCode, path) => {
    app.onShowMessage({
      detail: {
        variant: 'danger',
        str: errorMsg || `${i18n.rawText!.error.unknownerror} ${errorCode}`,
      },
    });

    if (!errorMsg) {
      Log.error(new Error(`API returned error "${errorCode}" calling ${path}`));
    }
  },
);

[API, legacyAPI, UploadAPI].forEach((apiClient) => {
  apiClient.use(errorMessageMiddleWare);
  apiClient.use(authorizationMiddleWare);
  apiClient.use(authenticationMiddleWare);
});

User.onUserUpdated = (updatedProps) => {
  if (updatedProps?.timezone) {
    moment.tz.setDefault(updatedProps.timezone);
  }
};

const init = async () => {
  try {
    matomoTracker.init(import.meta.env.VITE_MATOMO_CONTAINER);
    const {
      data: { user = {}, error },
    } = await legacyAPI.POST('/appinit');
    User.updateUser(
      error === 'tfarequired' ? { id: 'unknown', emailverified: 1 } : user,
    );
  } catch {
    // let's continue without tracker and/or without user
  }
  await i18n.setLanguage(User.preferredLanguage);

  moment.locale(i18n.language);
  Object.assign(window, {
    T: i18n.txt,
    prismicContent: i18n.prismicContent,
  });
};

init()
  .then(() => {
    render(app, document.body);
  })
  .catch((err) => {
    Log.error(err);
  });

window.sentryOnLoad = function () {
  window.Sentry?.init({
    release: import.meta.env.VITE_APPVERSION,
    environment: window.location.hostname,
    enabled: !!Number(import.meta.env.VITE_SENTRY_ON),
    allowUrls: /https:\/\/(.*)?\.yoummday\.com/u,
    maxBreadcrumbs: 30,
  });
};

declare global {
  interface Window {
    Sentry: { init: (arg: Record<string, unknown>) => void };
    T: typeof i18n.rawText;
    sentryOnLoad: () => void;
  }
}
