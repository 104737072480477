export default <Record<string, ManageRoute>>{
  '/findtalents/:companyuser': {
    as: 'recruitment/findtalents',
    hasRocketchat: true,
    module: () => import('./findtalents'),
  },
  '/recruitment/findtalents/:companyuser': {
    as: 'recruitment/findtalents',
    hasRocketchat: true,
    isRecruiting: true,
    module: () => import('./findtalents'),
  },
  '/recruitment/talent/:talentId': {
    as: 'recruitment/talentdetails',
    isRecruiting: true,
    module: () => import('../talentdetails'),
  },
  '/recruitment/worktypes': {
    as: 'recruitment/worktypes',
    module: () => import('./worktypes'),
  },
  '/recruitment/worktypes/create': {
    as: 'recruitment/worktype',
    module: () => import('./worktype'),
  },
  '/recruitment/worktypes/edit/:id': {
    as: 'recruitment/worktype',
    module: () => import('./worktype'),
  },
  '/recruitment/job-offers': {
    as: 'recruitment/job-offers',
    module: () => import('./job-offers'),
  },
  '/recruitment/job-offers/create/:worktypeId': {
    as: 'recruitment/job-offer',
    module: () => import('./job-offer'),
  },
  '/recruitment/job-offers/edit/:id': {
    as: 'recruitment/job-offer',
    module: () => import('./job-offer'),
  },
  '/recruitment/management': {
    as: 'recruitment/management',
    module: () => import('./management'),
  },
};
