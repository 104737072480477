export default <Record<string, ManageRoute>>{
  '/cuaccount/:companyuser/:settingsgroup': {
    as: 'cuaccount',
    hasRocketchat: true,
    module: () => import('.'),
  },
  '/cuaccount/:companyuser': {
    as: 'cuaccount',
    hasRocketchat: true,
    module: () => import('.'),
  },
};
