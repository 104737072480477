export default <Record<string, ManageRoute>>{
  '/watchlists/:companyuser/:listId': {
    as: 'watchlists',
    hasRocketchat: true,
    module: () => import('.'),
  },
  '/watchlists/:companyuser': {
    as: 'watchlists',
    hasRocketchat: true,
    module: () => import('.'),
  },
};
