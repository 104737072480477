export default <Record<string, ManageRoute>>{
  '/shifts/:companyuser/:calendarId/:moduleId': {
    as: 'shifts',
    hasRocketchat: true,
    module: () => import('.'),
  },
  '/shifts/:companyuser/:calendarId': {
    as: 'shifts',
    hasRocketchat: true,
    module: () => import('.'),
  },
  '/shifts/:companyuser': {
    as: 'shifts',
    hasRocketchat: true,
    module: () => import('.'),
  },
};
