import createClient, {
  deepNestedObjectToQueryString,
  objectToFormData,
} from '@yoummday/ymmd-api-client';
import type { paths } from './endpoint-definitions';

export const apiHost = import.meta.env.VITE_APIHOST;
export const API = createClient({
  baseUrl: `${apiHost}v2/`,
  credentials: 'include',
});

export const legacyAPI = createClient<paths>({
  baseUrl: `${apiHost}manage/`,
  credentials: 'include',
  bodySerializer: deepNestedObjectToQueryString,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  },
});

export const UploadAPI = createClient<paths>({
  baseUrl: `${apiHost}manage/`,
  bodySerializer: objectToFormData,
  credentials: 'include',
});
