export default <Record<string, ManageRoute>>{
  '/qualityadd/:companyuser/:formid': {
    as: 'qualityadd',
    hasRocketchat: true,
    module: () => import('./qualityadd.js'),
  },
  '/qualityadd/:companyuser': {
    as: 'qualityadd',
    hasRocketchat: true,
    module: () => import('./qualityadd.js'),
  },
  '/shiftplanning/:companyuser/:calendarId/:moduleId': {
    as: 'shiftplanning',
    module: () => import('./shiftplanning.js'),
  },
  '/shiftplanning/:companyuser/:calendarId': {
    as: 'shiftplanning',
    module: () => import('./shiftplanning.js'),
  },
  '/shiftplanning/:companyuser': {
    as: 'shiftplanning',
    module: () => import('./shiftplanning.js'),
  },
};
