export default <Record<string, ManageRoute>>{
  '/customreport/:companyuser': {
    as: 'customreport',
    module: () => import('.'),
  },
  '/customreport/:companyuser/:queryid': {
    as: 'customreport',
    module: () => import('.'),
  },
};
