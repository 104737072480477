export default <Record<string, ManageRoute>>{
  '/trainings/:trainingId': {
    as: 'trainings',
    hasRocketchat: true,
    module: () => import('.'),
  },
  '/trainings': {
    as: 'trainings',
    hasRocketchat: true,
    module: () => import('.'),
  },
};
