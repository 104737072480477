export default {
  '/bonuses/:companyuser': {
    as: 'bonuses',
    module: () => import('.'),
  },
  '/bonuses/:companyuser/:bonuslistId': {
    as: 'bonuses',
    module: () => import('.'),
  },
};
