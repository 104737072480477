export default <Record<string, ManageRoute>>{
  '/workforce/:companyuser/:moduleId/:teamIds/:contractState': {
    as: 'workforce',
    hasRocketchat: true,
    module: () => import('.'),
  },
  '/workforce/:companyuser/:moduleId/:contractState': {
    as: 'workforce',
    hasRocketchat: true,
    module: () => import('.'),
  },
  '/workforce/:companyuser/:moduleId': {
    as: 'workforce',
    hasRocketchat: true,
    module: () => import('.'),
  },
  '/workforce/:companyuser': {
    as: 'workforce',
    hasRocketchat: true,
    module: () => import('.'),
  },
};
