export default <Record<string, ManageRoute>>{
  '/pwreset/:token': {
    as: 'pwreset',
    hasHeader: false,
    hasAsideNavigation: false,
    isAuthRequired: false,
    hasPageTracking: true,
    module: () => import('./'),
  },
};
