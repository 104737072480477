export default <Record<string, ManageRoute>>{
  '/quality/qualitycheckdetails/:companyuser/:talentid/:formid/:reviewid': {
    as: 'quality/quality-check-details',
    module: () => import('./quality-check-details'),
  },
  '/quality/:companyuser/:talentid/:formid': {
    as: 'quality',
    hasRocketchat: true,
    module: () => import('.'),
  },
  '/quality/:companyuser/:formid': {
    as: 'quality',
    hasRocketchat: true,
    module: () => import('.'),
  },
  '/quality/:companyuser': {
    as: 'quality',
    hasRocketchat: true,
    module: () => import('.'),
  },
};
