export default <Record<string, ManageRoute>>{
  '/training/:trainingId': {
    as: 'training',
    hasRocketchat: true,
    module: () => import('.'),
  },
  '/training': {
    as: 'training',
    hasRocketchat: true,
    module: () => import('.'),
  },
};
