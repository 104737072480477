import YmmdRouter from '@yoummday/ymmd-router';
import { User } from '../User';
import { Log } from '@yoummday/ymmd-logger';
import { matomoTracker } from '../matomoTracker';
import { socket } from '@yoummday/ymmd-websocket';
import {
  createAlertMessageEvent,
  getAppElement,
} from '@yoummday/ymmd-platform-core/comp/app-shell';

const Router = new YmmdRouter(import.meta.env.BASE_URL);

Router.notFound(() => {
  const redirectTo = User.hasSession ? '/notfound' : '/login';
  Router.navigate(redirectTo, {
    updateBrowserURL: false,
  });
  if (User.hasSession) {
    const { url } = Router.getCurrentLocation();
    Log.error(new Error(`Not found: ${url}`));
  }
});

const sessionOk = (ymdRoute: ManageRoute) => {
  if (ymdRoute.isAuthRequired && !User.hasSession) {
    Router.navigate('/login', {
      updateBrowserURL: false,
    });
    return false;
  }
  return true;
};

const isAccessAllowed = (ydRoute: ManageRoute): boolean => {
  if (
    User.hasSession &&
    !User.canAccess(ydRoute.as, ydRoute.isCompanyUserRequired)
  ) {
    getAppElement()?.dispatchEvent(
      createAlertMessageEvent(window.T.error.notallowed, 'warning'),
    );
    return false;
  }
  return true;
};

const shouldRedirectToProjectOverview = async (
  ydRoute: ManageRoute,
): Promise<boolean> => {
  const companies = (await User.clients) || [];
  if (ydRoute.as === 'home' && companies.length === 1) {
    Router.navigate(`/projects/${User.company?.id}`);
    return false;
  }
  return true;
};

Router.declareDefaultRouteConfig(<Omit<ManageRoute, 'as'>>{
  hasPageTracking: true,
  isCompanyUserRequired: true,
  hasRocketchat: false,
  hooks: {
    before: async (done, match, ymdRoute: ManageRoute) => {
      if (ymdRoute.isCompanyUserRequired) {
        await User.setCompany(match.data?.companyuser);
      }
      done(
        sessionOk(ymdRoute) &&
          isAccessAllowed(ymdRoute) &&
          (await shouldRedirectToProjectOverview(ymdRoute)),
      );
    },
    after: (match, ymdRoute) => {
      if (ymdRoute.hasPageTracking) matomoTracker.pageTrack();
      socket.initAndAuthorize(
        User.company?.websocket,
        import.meta.env.VITE_YOUMMDAY_SOCKET,
      );
    },
  },
});

export { Router };
