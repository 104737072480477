/* eslint-disable max-lines */
import { Model } from '../../modules/Model.js';
import { User } from '../../modules/User.ts';
import { clock } from '@yoummday/ymmd-platform-core/utils';
import { html, render } from 'lit';
import { until } from 'lit/directives/until.js';
import { when } from 'lit/directives/when.js';
import '../mail-indicator/index.ts';
import {
  createAlertMessageEvent,
  createLogoutEvent,
  createRerenderEvent,
  showAppDialog,
} from '@yoummday/ymmd-platform-core/comp/app-shell';
import { i18n } from '@yoummday/ymmd-platform-core';
import { Router } from '../../modules/router';

const switchToClient = async (companyuser) => {
  const hasSwitched = await User.switchCompany(companyuser);
  if (hasSwitched) {
    const [{ url, data }] = Router.lastResolved();
    if (data?.companyuser) {
      const [routeName] = url.split('/');
      Router.navigate(`/${routeName}/${companyuser}`);
      return true;
    }
  }
  return false;
};

// eslint-disable-next-line max-lines-per-function
const companiesDropdown = async () => {
  if (!User.company) return '';
  const companies = await User.clients;

  // eslint-disable-next-line max-lines-per-function
  const addClient = async function (e) {
    e.preventDefault();
    const btn = e.currentTarget;
    const form = document.createElement('form');
    render(
      html`
        <label>${window.T.field.label.company}</label>
        <input
          type="text"
          placeholder="${window.T.field.placeholder.company}"
          name="company"
          required
        />
        <label for="addrline1">${window.T.field.label.address}</label>
        <input
          type="text"
          id="addrline1"
          placeholder="${window.T.field.placeholder.address}"
          name="addrline1"
          maxlength="80"
          required
        />
        <label for="addrline2">
          ${window.T.field.label.address_additional}
        </label>
        <input
          type="text"
          id="addrline2"
          placeholder="${window.T.field.placeholder.address_additional}"
          name="addrline2"
          maxlength="80"
        />
        <label for="zip">${window.T.field.label.zip}</label>
        <input
          type="text"
          id="zip"
          maxlength="15"
          placeholder="${window.T.field.placeholder.zip}"
          name="zip"
          required
        />
        <label for="city">${window.T.field.label.city}</label>
        <input
          type="text"
          id="city"
          placeholder="${window.T.field.placeholder.city}"
          name="city"
          required
        />
        <label>${window.T.field.label.customer_type}</label>
        <select name="productPlan">
          <option value="managedservice" selected>
            ${window.T.field.option.productPlan.managedservice}
          </option>
          <option value="selfservice">
            ${window.T.field.option.productPlan.selfservice}
          </option>
        </select>
        <label>${window.T.field.placeholder.email}</label>
        <input
          type="email"
          name="email"
          placeholder="${window.T.field.placeholder.email}"
          autocomplete="off"
          required
        />
        <input
          type="hidden"
          name="subdomain"
          value=${location.host.split('.')[0]}
        />
        <label>${window.T.field.label.country}</label>
        <select name="country" required>
          ${Object.entries(i18n.statics?.countries).map(
            ([cc, country]) => html`
              <option value=${cc} ?selected=${cc === User.user.country}>
                ${country}
              </option>
            `,
          )}
        </select>
        <label>${window.T.field.label.tax_vatid}</label>
        <input
          type="text"
          placeholder="${window.T.field.placeholder.tax_vatid}"
          name="euVatId"
        />
      `,
      form,
    );

    const { value: formData } = await showAppDialog({
      html: form,
      titleText: window.T.headline.client_create,
      confirmButtonText: window.T.cta.save,
      preConfirm: () =>
        form.reportValidity() ? form.serializeObject() : false,
      didOpen: () => {
        const emailInput = form.querySelector('[name="email"]');
        const companyNameInput = form.querySelector('[name="company"]');
        companyNameInput.focus();
        if (User.ymmdTeam) {
          let transferNameToEmail = true;
          emailInput.addEventListener(
            'change',
            () => {
              transferNameToEmail = false;
            },
            { once: true },
          );
          companyNameInput.addEventListener('input', (ev) => {
            const input = ev.currentTarget;
            if (!transferNameToEmail) return;
            emailInput.value = `kunden+${input.value
              .toLowerCase()
              .replace(/\s/gu, '-')
              .replace(/[^\w|-]/giu, '')}@yoummday.com`;
          });
        }
      },
    });
    if (formData) {
      const { user } = await Model.data.admincu(formData);
      if (user) {
        await User.addCompany(user);
        btn.dispatchEvent(
          createAlertMessageEvent(window.T.alert.success.saved),
        );
        const didNavigate = await switchToClient(user.id);
        if (!didNavigate) btn.dispatchEvent(createRerenderEvent());
      }
    }
  };
  return html`
    ${when(
      companies.length >= 2 || User.can('projectmanageAdmin'),
      // eslint-disable-next-line max-lines-per-function
      () => html`
        <yd-dropdown .direction=${'right-bottom'} class="d-block">
          <div slot="toggle">
            <a href="#" @click=${(e) => e.preventDefault()}>
              <span>
                <iconify-icon
                  icon="mdi-account-convert"
                  class="iconify"
                ></iconify-icon>
              </span>
              ${window.T.cta.change_account}
            </a>
          </div>
          <div slot="content">
            <yd-card class="p-2">
              ${when(
                User.can('projectmanageAdmin'),
                () => html`
                  <nav>
                    <a href="#" @click=${addClient}>
                      <span>
                        <iconify-icon
                          icon="mdi-domain-plus"
                          class="iconify"
                        ></iconify-icon>
                      </span>
                      ${window.T.cta.customer_add}
                    </a>
                  </nav>
                  <hr class="my-1" />
                `,
              )}
              <nav style="overflow-y: auto;max-height:300px;">
                ${companies.map(
                  (company) => html`
                    <a
                      href="#"
                      @click=${async (e) => {
                        e.preventDefault();
                        const btn = e.currentTarget;
                        const didNavigate = await switchToClient(company.id);
                        if (!didNavigate) {
                          btn.dispatchEvent(createRerenderEvent());
                        }
                      }}
                    >
                      <div class="d-flex align-items-center flex-nowrap">
                        <user-thumb
                          .user=${company}
                          size="30"
                          noTitle
                        ></user-thumb>
                        <span class="ellipsis ms-2" style="max-width:180px">
                          ${company.company || window.T.term.untitled}
                        </span>
                      </div>
                    </a>
                  `,
                )}
              </nav>
            </yd-card>
          </div>
        </yd-dropdown>
      `,
    )}
  `;
};

// eslint-disable-next-line max-lines-per-function
export const header = (scope = 'default') => {
  const canRenderNav = !!User.company;
  return html`
    <div class="row justify-content-between align-items-center">
      <app-link href="/" class="logo col-auto">
        <iconify-icon icon="ymmd-logo" width="115"></iconify-icon>
      </app-link>
      <div class="col-auto d-flex align-items-center gap-3">
        ${when(
          User.company?.timezone,
          () => html`
            <div class="d-none d-sm-inline-block">
              <span class="me-1">${clock(User.company.timezone)}</span>
              <span>${User.company.timezone.split('/').reverse()[0]}</span>
            </div>
          `,
        )}
        ${when(
          User.canAccess('mailbox'),
          () => html`
            <app-link href="/mailbox/${User.company.id}">
              <mail-indicator
                style="min-width: auto"
                companyuser="${User.company.id}"
              ></mail-indicator>
            </app-link>
          `,
        )}

        <a
          class="d-inline-block"
          href="${window.T.href.help_center}"
          target="_blank"
          class="text-dark"
        >
          <yd-tooltip tip=${window.T.nav.help_center}>
            <iconify-icon
              icon="mdi-help-circle"
              class="iconify"
              width="24"
            ></iconify-icon>
          </yd-tooltip>
        </a>
        ${when(
          scope === 'recruiting',
          () => html`
            <div class="recruiting-icon">
              <iconify-icon
                icon="mdi-handshake"
                class="iconify"
                width="24"
              ></iconify-icon>
            </div>
          `,
          // eslint-disable-next-line max-lines-per-function
          () => html`
            <yd-dropdown .direction=${'bottom-right'}>
              <div slot="toggle">
                <user-thumb .user=${User.company || {}} size="40"></user-thumb>
              </div>
              <div slot="content">
                <yd-card
                  hasBorder
                  hasShadow
                  class="rounded-0 p-4 mt-2"
                  style="max-width:300px"
                >
                  ${when(
                    canRenderNav,
                    () => html`
                      <h6 class="ellipsis">
                        ${User.company?.company || window.T.term.untitled}
                      </h6>
                      <nav>
                        ${until(companiesDropdown(), '')}
                        ${when(
                          User.canAccess('cuaccount'),
                          () => html`
                            <app-link href="cuaccount/${User.company.id}">
                              <span>
                                <iconify-icon
                                  icon="mdi-domain"
                                  class="iconify"
                                ></iconify-icon>
                              </span>
                              ${window.T.nav.company_profile}
                            </app-link>
                          `,
                        )}
                        ${when(
                          User.hasRight('adminpm') || User.hasRight('admin'),
                          () => html`
                            <app-link href="members/${User.company.id}">
                              <span>
                                <iconify-icon
                                  icon="mdi-account-cog"
                                  class="iconify"
                                ></iconify-icon>
                              </span>
                              ${window.T.nav.users}
                            </app-link>
                          `,
                        )}
                      </nav>
                    `,
                  )}
                  ${when(
                    !!User.company && User.user.id !== User.company?.id,
                    () => html`
                      <hr />
                      <h6 class="ellipsis mt-3">
                        ${User.user.name || User.user.email}
                      </h6>
                    `,
                  )}
                  <nav>
                    ${when(
                      canRenderNav,
                      () => html`
                        <app-link href="account">
                          <span>
                            <iconify-icon
                              icon="mdi-user"
                              class="iconify"
                            ></iconify-icon>
                          </span>
                          ${window.T.nav.account}
                        </app-link>
                      `,
                    )}
                    <a
                      href="#"
                      @click=${(e) => {
                        e.preventDefault();
                        e.currentTarget.dispatchEvent(createLogoutEvent(true));
                      }}
                    >
                      <span>
                        <iconify-icon
                          icon="mdi-logout"
                          class="iconify"
                        ></iconify-icon>
                      </span>
                      ${window.T.nav.logout}
                    </a>
                  </nav>
                </yd-card>
              </div>
            </yd-dropdown>
          `,
        )}
      </div>
    </div>
  `;
};
