import type { ArbitraryArrowFunction } from '@yoummday/ymmd-router/dist/types';

// Paypal charge success url
export default <Record<string, ManageRoute>>{
  '/finance/:result': {
    as: 'paypalCallback',
    hasRocketchat: true,
    module: () => import('.'),
    uses: (match, ymdRoute, router) =>
      (ymdRoute.routeDefaultExport as ArbitraryArrowFunction)(
        match.params?.token,
        match.data?.result,
        router.navigate,
      ),
  },
};
