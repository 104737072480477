export default <Record<string, ManageRoute>>{
  '/project/:companyuser/:mnemonic/:settingsgroup': {
    as: 'project',
    hasRocketchat: true,
    module: () => import('.'),
  },
  '/project/:companyuser/:mnemonic': {
    as: 'project',
    hasRocketchat: true,
    module: () => import('.'),
  },
  '/project/:companyuser': {
    as: 'project',
    hasRocketchat: true,
    module: () => import('.'),
  },
};
