import type { ArbitraryArrowFunction } from '@yoummday/ymmd-router/dist/types';

export default <Record<string, ManageRoute>>{
  '/redirecting/hubspot?:code': {
    as: 'redirectHubspot',
    module: () => import('.'),
    uses: (match, ymdRoute, router) =>
      (ymdRoute.routeDefaultExport as ArbitraryArrowFunction)(
        match.params?.code,
        match.params?.state,
        router.navigate,
      ),
  },
};
