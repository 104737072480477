import { render, html, nothing, css, unsafeCSS } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { when } from 'lit/directives/when.js';
import AppShell, {
  type createNavigateEvent,
} from '@yoummday/ymmd-platform-core/comp/app-shell';
import { User } from '../../modules/User';
import { legacyAPI } from '../../modules/api';
import { Router } from '../../modules/router';

import Template from '../../modules/Template.js';
import type Page from '@yoummday/ymmd-platform-core/comp/app-page';
import { header } from './header.js';
import { aside } from './aside.js';
import { socket } from '@yoummday/ymmd-websocket';
import type { PushMsg } from '@yoummday/ymmd-websocket/dist/types';
import { Log } from '@yoummday/ymmd-logger';
import { br, notification } from '@yoummday/ymmd-platform-core/utils';
import { Storage } from '@yoummday/ymmd-client-storage';
import { i18n } from '../../i18n';
import '@yoummday/ymmd-platform-core/comp/chat-launcher';
import '@yoummday/ymmd-platform-core/comp/main-player';
import styles from './style.scss?inline';
import platformBackground from './assets/platform-background.svg?no-inline';
import type { APItalent } from '../../modules/api/api-user';
import type YmmdRouter from '@yoummday/ymmd-router';
import type { NavigationScope } from '../navigation-switch/types';

const routeFiles: Record<string, YmmdRouter['ymdRoutes']> = import.meta.glob(
  ['../../**/_routes.ts'],
  {
    eager: true,
    import: 'default',
  },
);

Object.values(routeFiles).forEach((routes) => {
  Router.registerRoutes(routes);
});

@customElement('app-manage')
export default class AppManage extends AppShell {
  static styles = [
    styles,
    css`
      :host {
        background-image: ${unsafeCSS(`url(${platformBackground})`)};
      }
    `,
  ];

  view?: Page | Template;
  @state() route?: ManageRoute;
  @state() scope: NavigationScope = 'default';

  // deprecated: modals/dialogs/whatnot should be replaced with yd-dialog and handled in their calling views
  @query('.modal') modalElement!: HTMLElement;
  modal?: Template & { name: string };

  // deprecated: legacy container is for Template Class Instance
  @query('#legacy-page') legacyContainer!: HTMLElement;

  localStorage = new Storage(this.nameSpace, 'local');

  seenPushes: string[] = [];

  connectedCallback(): void {
    this.addEventListeners();
    super.connectedCallback();
    Router.subscribe(async (route, params, queryParams) => {
      if (!route.routeDefaultExport) return;
      if (this.view instanceof Template) this.view.remove();
      if (route.routeDefaultExport.isLegacy) {
        this.view = new route.routeDefaultExport(
          // eslint-disable-next-line no-undefined
          undefined,
          params || {},
          queryParams || {},
          route,
        ) as Template;
        if (!this.view) return;

        render(nothing, this);
        render(this.view.container, this.legacyContainer);
        await this.view.init();
      } else {
        this.view = new route.routeDefaultExport();
        if (!this.view) return;
        this.view.params = params || {};
        this.view.query = queryParams || {};
        this.view.route = route;
        render(nothing, this.legacyContainer);
        render(this.view, this);
      }

      this.route = route;
    });
  }

  onLogout({ detail: onPurpose }: CustomEvent<boolean>) {
    delete User.user;
    Router.navigate('/login', {
      updateBrowserURL: false,
    });
    socket.kill();
    sessionStorage.clear();
    if (!onPurpose) {
      this.showSnackBar({
        str: window.T.error.auth,
        variant: 'danger',
      });
    } else {
      legacyAPI.POST('/logout');
    }
  }

  onNavigate(e: ReturnType<typeof createNavigateEvent>) {
    Router.navigate(e.detail);
  }

  // eslint-disable-next-line max-lines-per-function
  render() {
    return html`
      <article class=${classMap({ 'has-header': !!this.route?.hasHeader })}>
        ${when(
          User.hasSession,
          () => html`
            ${when(
              this.route?.hasAsideNavigation,
              () => html`
                <aside
                  class=${this.scope === 'recruiting' ? 'collapsible' : ''}
                >
                  ${aside(this.scope)}
                </aside>
              `,
            )}
          `,
        )}

        <main>
          ${when(
            User.hasSession && User.company?.wantsSetupProgress === 1,
            () => html`
              <setup-progress
                .currentRoute=${Router.getCurrentLocation()?.url}
              ></setup-progress>
            `,
          )}
          <main-player>
            <section
              class=${classMap({
                'view-container': true,
                'no-padding':
                  !this.route?.hasHeader && !this.route?.hasAsideNavigation,
              })}
            >
              ${when(
                this.isLoading,
                () => html` <div class="loading-bar"></div> `,
              )}

              <slot></slot>
              <div id="legacy-page"></div>
            </section>
          </main-player>
        </main>
        ${when(
          User.hasSession && this.route?.hasRocketchat,
          () => html`
            <chat-launcher
              .collisionPrevention=${User.isSelfservice}
              .rcHost=${import.meta.env.VITE_ROCKETCHAT_HOSTNAME}
              .apiHost=${import.meta.env.VITE_APIHOST}
              .appVersion=${import.meta.env.VITE_APPVERSION}
            ></chat-launcher>
          `,
        )}
      </article>

      ${when(
        this.route?.hasHeader && User.hasSession,
        () => html` <header>${header(this.scope)}</header> `,
      )}

      <div class="modal"></div>

      ${when(this.dialog, () => this.renderDialog())}
      <!---->
      ${this.renderSnackBar()}
    `;
  }

  // eslint-disable-next-line max-lines-per-function
  addEventListeners() {
    socket.Log = Log;
    socket.subscribe(this, 'push', ({ detail: { msgs = [] } }) => {
      if (
        !msgs.some(
          ({ answer, id }) =>
            !answer && // not answered yet
            !(this.localStorage.get('seenPushes') || []).includes(id), // not yet seen
        )
      ) {
        return;
      }
      const singlePush = (push: PushMsg) => {
        const container = document.createElement('div');
        render(
          html`
            <div style="display:flex; align-items:center;">
              <div>
                <user-thumb
                  .user=${push.user || { ymdItself: true }}
                  size="40"
                ></user-thumb>
              </div>
              <div>
                <h5 class="m-0">${push.title}</h5>
              </div>
            </div>
            <p>${br(push.text)}</p>
          `,
          container,
        );
        return container;
      };
      notification(window.T.message.event.pushmsg, '', i18n.language);
      msgs.forEach((push) => {
        const container = singlePush(push);
        this.onShowToast(
          new CustomEvent('showToast', {
            detail: {
              element: container,
              variant: 'neutral',
              onDismissed: () => {
                this.localStorage.push('seenPushes', push.id);
              },
            },
          }),
        );
      });
    });
    this.addEventListener(
      'switchNavigationScope',
      ({ detail: { scope } }: CustomEvent) => {
        this.scope = scope;
      },
    );
    // [data-for] and [name="tabs-*"] evt listening should be replaced with something new and <yd-tabs>
    this.shadowRoot?.addEventListener('change', (e) => {
      const target = e.target as HTMLInputElement;

      if (typeof target.name === 'string' && target.name?.startsWith('tab')) {
        this.shadowRoot?.querySelectorAll('[for^="tab"]').forEach((forTab) => {
          const activateTab = this.shadowRoot?.querySelector(
            `#${forTab.getAttribute('for')}`,
          ) as HTMLInputElement;
          if (activateTab && 'checked' in activateTab) {
            forTab.classList.toggle('active', activateTab.checked);
          }
        });
      }

      if (target.hasAttribute('data-for')) {
        const dataFor = target.getAttribute('data-for') as string;
        const checkboxes = this.shadowRoot?.querySelectorAll(
          dataFor,
        ) as NodeListOf<HTMLInputElement>;
        checkboxes.forEach((checkbox) => {
          checkbox.checked = target.checked;
          checkbox.dispatchEvent(new Event('change', { bubbles: true }));
        });
      }
    });
  }

  // just a bridge for legacy Model-Class
  userUpdated(user: APItalent) {
    User.updateUser(user);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'app-manage': AppManage;
  }
  interface HTMLElementEventMap {
    showModal: CustomEvent<{ name: string; data: Record<string, unknown> }>;
    destroyModal: CustomEvent;
    switchNavigationScope: CustomEvent<{ scope: NavigationScope }>;
  }
}
