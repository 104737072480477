export default <Record<string, ManageRoute>>{
  '/teams/:companyuser/:teamId': {
    as: 'teams',
    hasRocketchat: true,
    module: () => import('.'),
  },
  '/teams/:companyuser': {
    as: 'teams',
    hasRocketchat: true,
    module: () => import('.'),
  },
};
