import type { APIcompany } from './api/api-user.js';
import { legacyAPI } from './api/index.js';
import { Model } from './Model.js';
import { User as BaseUser } from '@yoummday/ymmd-platform-core';

class UserManage extends BaseUser {
  company: APIcompany | null = null;

  static accessMap = new Map([
    [
      // these routes can be accessed if
      ['stats', 'stats-talents'],
      // dashboardRights includes at least one of these
      ['stats', 'statslimited'],
    ],
    [['workforce'], ['workforce']],
    [['bonuses'], ['bonuspay']],
    [['report'], ['stats', 'statslimited', 'report']],
    [['schedule', 'shifts', 'shiftplanning'], ['calendar']],
    [
      ['quality', 'qualityadd', 'qualitycheck', 'qualityview'],
      ['qualitycheck'],
    ],
    [['voicefiles'], ['voicefile', 'qualitycheck']],
    [['users'], ['adminpm']],
    [['watchlists', 'trainings', 'talentmonitor', 'teams'], ['workforce']],
    [
      ['news', 'chatgroups'],
      ['workforce', 'message'],
    ],
    [['balance'], ['invoice']],
    [['mailbox'], ['message']],
    [['worktypes', 'joboffers'], ['worktypes']],
    [['cuaccount'], ['owner']],
    [['project', 'addservice'], ['modulemanage']],
    [['ibcalls', 'lists'], ['curelations']],
    [['earnings'], ['stats']],
    [['mapping'], ['mapping']],
    [['vpncode'], ['dailylogintoken']],
    [['recruiting'], ['rec_manager', 'rec_trainer', 'rec_recruiter']],
  ]);

  get clients(): Promise<(APIcompany & { volumenwarn: unknown[] })[]> {
    return (async () => {
      if (!this.hasSession) return [];
      const { users = [], modules, volumewarn } = await Model.stored.users();
      if (!users.length) return users;
      return users
        .sort((a: APIcompany, b: APIcompany) =>
          a.company.localeCompare(b.company),
        )
        .map((u: APIcompany) => ({
          ...u,
          dashboardSettings: u.dashboardSettings || [],
          modules: modules[u.id] || {},
          volumewarn: volumewarn.filter((warn) => warn.userId === u.id),
        }));
    })();
  }

  get teams() {
    return (async () => {
      if (!this.hasSession || !this.company) return [];
      const { teams = [] } = await Model.stored.teams({
        companyuser: this.company?.id,
        action: 'list',
      });
      return teams;
    })();
  }

  get watchlists() {
    return (async () => {
      if (
        !this.hasSession ||
        !this.company?.id ||
        !this.hasRight('workforce')
      ) {
        return {};
      }
      const { lists } = await Model.stored.preselectlist({
        action: 'list',
        companyuser: this.company.id,
      });
      return lists;
    })();
  }

  get trainings() {
    return (async () => {
      if (
        !this.hasSession ||
        !this.company?.id ||
        !this.hasRight('workforce')
      ) {
        return [];
      }
      const { trainings } = await Model.stored.training({
        action: 'list',
        companyuser: this.company.id,
      });
      return trainings;
    })();
  }

  get isSelfservice() {
    return this.company?.productPlan === 'selfservice';
  }

  get isManaged() {
    return this.company?.productPlan === 'managedservice';
  }

  get isoCurrency() {
    return this.user?.lang === 'en' &&
      this.user?.email.endsWith('+demo@yoummday.com')
      ? 'USD'
      : 'EUR';
  }

  get currencySymbol() {
    return {
      EUR: '€',
      USD: '$',
    }[this.isoCurrency];
  }

  get canRecruit() {
    return this.hasRight('workforce') && this.ymmdTeam;
  }

  async getModules(id: APIcompany['id']) {
    const { modules = [], calendarIds = {} } = await Model.data.modules({
      companyuser: id,
    });

    return Object.fromEntries(
      modules
        .sort((a, b) =>
          (a.title || window.T.term.untitled).localeCompare(
            b.title || window.T.term.untitled,
          ),
        )
        .map((mod) => [
          mod.mnemonic,
          {
            ...mod,
            calendarId:
              Object.entries(calendarIds).reduce(
                (returnId, [calId, mnemonics]) =>
                  `${returnId}${mnemonics.includes(mod.mnemonic) ? calId : ''}`,
                '',
              ) || null,
          },
        ]),
    );
  }

  // eslint-disable-next-line max-lines-per-function
  async setCompany(id = '') {
    const companies = await this.clients;
    const wantedCompany =
      companies.find((u) => u.id === id) || // requested company by id
      (this.company?.id ? this.company : null) || // already set company
      companies.find((u) => u.dashboardSettings.chosen) || // last time chosen company
      companies.find((u) => u.me) || // identified company by 'me' property
      companies[0]; // first company in list

    if (
      !wantedCompany ||
      (this.company?.id && this.company?.id === wantedCompany.id)
    ) {
      return;
    }

    // remove data from storage as it doesn't belong to the wantedComapny
    [...Model.Storage.keys()].forEach((k) => {
      if (k !== 'users') Model.Storage.remove(k);
    });

    wantedCompany.modules = await this.getModules(wantedCompany.id);

    if (this.company?.id && this.company?.id !== wantedCompany.id) {
      const {
        data: { settings: dashboardSettings },
      } = await legacyAPI.POST('/settings', {
        body: {
          userid: this.company?.id,
          setting: {
            chosen: '',
          },
        },
      });
      this.mutateCompany({ dashboardSettings }, this.company.id);
      this.company = wantedCompany;

      const {
        data: { settings },
      } = await legacyAPI.POST('/settings', {
        body: {
          userid: this.company?.id,
          setting: {
            chosen: 1,
          },
        },
      });
      this.mutateCompany({ dashboardSettings: settings }, this.company?.id);
    } else {
      this.company = wantedCompany;
    }
  }

  mutateCompany(companyProps = {}, id = '') {
    if (!id || id !== this.company?.id) return;
    const { users } = Model.Storage.get('users') || {};
    const companyPosition = users.findIndex((u) => u.id === id);
    users[companyPosition] = {
      ...this.company,
      ...companyProps,
    };
    Model.Storage.update('users', { users });
  }

  async addCompany(company = {}) {
    if (Object.keys(company).length) {
      Model.Storage.remove('users');
      await this.clients;
    }
  }

  async switchCompany(companyId: string) {
    if (!companyId || companyId === this.company?.id) return false;
    await this.setCompany(companyId);
    return true;
  }

  async refreshModules() {
    Model.Storage.remove('modules');
    this.company!.modules = await this.getModules(this.company!.id);
    await this.setCompany();
  }

  async hasRightAtLeastOnce(accessDefinition: string[]) {
    const companies = await this.clients;

    return companies.some((company) =>
      company.dashboardRights.some((right) =>
        UserManage.accessMap.get(accessDefinition).includes(right),
      ),
    );
  }

  async canAccessGlobally(feature: string) {
    const accessDefinition = [...UserManage.accessMap.keys()].find((feat) =>
      feat.includes(feature),
    );

    if (!accessDefinition) return true;

    return await this.hasRightAtLeastOnce(accessDefinition);
  }

  canAccess(view = '', companyUserRequired: boolean = true) {
    if (!this.company?.id && companyUserRequired) return false;
    const accessDefinition = [...UserManage.accessMap.keys()].find((viewArr) =>
      viewArr.includes(view),
    );
    if (!accessDefinition) return true;

    if (import.meta.env.PROD) {
      const shiftplanTestMap = new Map([
        ['max.fraunhofer@yoummday.com', ['fr1u0jf9q', 'fr6nlelq0']],
        ['simona.hesse@yoummday.com', ['fr1u0jf9q', 'fr6nlelq0']],
      ]);
      if (view === 'shiftplanning' && this.company?.id) {
        const companies = shiftplanTestMap.get(this.user.email);
        if (!companies?.includes(this.company.id)) return false;
      }
    }

    return UserManage.accessMap
      .get(accessDefinition)
      ?.some((right) => this.hasRight(right));
  }

  hasRight(right = '') {
    return (
      !!right &&
      !!this.company?.dashboardRights &&
      this.company.dashboardRights.includes(right)
    );
  }
}

const User = new UserManage();
export { User };
